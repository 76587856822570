// 1. Colors
// ==========================================================================

$primary-color: #f6f6f6;
$secondary-color: lighten($primary-color, 20%);

// 13. Navigation Bar
// ==========================================================================

$navbar-font-color: darken($primary-color, 90%);

// 13. Navigation Bar
// ==========================================================================

$my-fonts: (
        'asap': "'Asap', sans-serif"
)